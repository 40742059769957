import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Moon({ size, color }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <BaseIconPathStyle
        d="M4.85634 7.99998C4.84744 6.61109 4.4771 5.24845 3.78176 4.04613C3.08641 2.8438 2.09002 1.84322 0.890625 1.14284C1.78597 0.77227 2.7445 0.578236 3.71348 0.571411C5.68366 0.571411 7.57315 1.35406 8.96628 2.74719C10.3594 4.14032 11.1421 6.0298 11.1421 7.99998C11.1421 9.97016 10.3594 11.8596 8.96628 13.2528C7.57315 14.6459 5.68366 15.4286 3.71348 15.4286C2.7445 15.4217 1.78597 15.2277 0.890625 14.8571C2.09002 14.1567 3.08641 13.1562 3.78176 11.9538C4.4771 10.7515 4.84744 9.38887 4.85634 7.99998V7.99998Z"
        stroke={color}
        strokeRound
      />
    </BaseSvgStyle>
  );
}

export default Moon;
